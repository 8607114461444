import { GET_PRESIGNED_URL } from './endpoints';
var axios = require('axios');

export default async function GetPresignedUrl(upload_type) {
    var config = {
        method: 'post',
        url: GET_PRESIGNED_URL,
        headers: {
            'upload_file_prefix': upload_type
        }
    };
    
    try {
        // fetch data from a url endpoint
        const data = await axios(config);
        return data;

    } catch (error) {
        console.log("error", error);
        return error;
        // appropriately handle the error
    }
}