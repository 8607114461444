import { GET_DEPARTMENTS } from './endpoints';
var axios = require('axios');

export default async function GetDepartments() {
    var config = {
        method: 'post',
        url: GET_DEPARTMENTS,
        headers: {}
    };
    
    try {
        // fetch data from a url endpoint
        const data = await axios(config);
        return data;

    } catch (error) {
        console.log("error", error);
        return error;
        // appropriately handle the error
    }






    // axios(config)
    //     .then(function (response) {

    //         /* eslint-disable no-eval */
    //         setDepartmentIds(eval(response.data))
    //         console.log(eval(response.data))
    //         setDepartmentIdLoading(false)
    //         /* eslint-disable no-eval */

    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });

}