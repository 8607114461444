import { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { Typography } from 'antd';
import ForecastForm from './components/forecast_form/index';
import Login from './components/authentication/login';
import LoadingOverlay from 'react-loading-overlay';

import { useLoadingContext } from './contexts/loading_provider';
import { useLoadingMessageContext } from './contexts/loading_message_provider';

import '@aws-amplify/ui-react/styles.css';
import Amplify, { Hub, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const { Text } = Typography;

function App() {
  const isLoading = useLoadingContext()
  const LoadingMessage = useLoadingMessageContext()

  const [user, setUser] = useState()

  useEffect(() => {
    Hub.listen('auth', (data) => {
      console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
      if (data.payload.event === 'signIn') setUser(data.payload.data)
        
    })
  })

  useEffect(() => {
    console.log(process.env.REACT_APP_RUNTIME_ENV)
    Auth.currentAuthenticatedUser().then(user => {
      console.log(user)
      setUser(user)
    }, err => {
      console.log(err)
    })
  }, [])


  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={LoadingMessage}
    >

      <div className="App">
        <div className="Content basic-background">
          <div className='Title-Bar'>
            <img
              src='https://earlyfriday.nl/wp-content/uploads/2021/05/Logo-print-horizontal_blue.svg'
              className="App-logo"
              alt="logo"
              style={{ paddingTop: 20 }}
            />
            <Text type="secondary">Automated forecasting and planning system</Text>
          </div>
        </div>
        <div className="form Content">

            {user ? <ForecastForm /> : <Login />}

        </div>
      </div>
    </LoadingOverlay>

  );
}

export default App;
