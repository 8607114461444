import 'antd/dist/antd.css';
import { useState, useEffect } from 'react';
import { Form, DatePicker, Button, Upload, Steps, Row, Col, Switch, Select, Typography, message, Space, Input } from 'antd';
import { UploadOutlined, LoadingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { formItemLayout, inputConfig, rangeConfig, normFile, last_actuals_config } from './config';
import GetDepartments from '../../utils/get_departments';
import GeneratePlanning from '../../utils/generate_planning';
import GenerateForecast from '../../utils/generate_forecast_file';
import GetPresignedUrl from '../../utils/get_presigned_url';
import Upload_File_To_S3 from '../../utils/upload_file_to_s3';

const { Step } = Steps;
const { RangePicker } = DatePicker;
const { Option } = Select;
const delay = ms => new Promise(res => setTimeout(res, ms));
export default function ForecastForm(props) {
    const [disableCustomForecast, setDisableCustomForecast] = useState(true);
    const [departmentIds, setDepartmentIds] = useState([]);
    const [departmentIdLoading, setDepartmentIdLoading] = useState(true);
    const [disableSubmission, setDisableSubmission] = useState(false);
    const [isGeneratingPlanning, setIsGeneratingPlanning] = useState(false);
    const [totalRuntime, setTotalRuntime] = useState(0);
    /* eslint-disable no-unused-vars */
    const [generatingPlanningStep, setGeneratingPlanningStep] = useState(0);
    /* eslint-enable no-unused-vars */

    const [uploadFileNames, setUploadFileNames] = useState({ priority: '', forecast: '' });
    const [uploadingState, setUploadingState] = useState();

    const reset = () => {
        setDisableCustomForecast(true);
        setDepartmentIds([]);
        setDepartmentIdLoading(true);
        setDisableSubmission(false);
        setIsGeneratingPlanning(false);
        setTotalRuntime(0);
        setUploadFileNames({ priority: '', forecast: '' });
        setUploadingState();
    }

    async function setDepartments() {
        let response = await GetDepartments()
        if (response.status === 200) {
            /* eslint-disable no-eval */
            setDepartmentIds(eval(response.data))
            /* eslint-disable no-eval */
            console.log(response)
            setDepartmentIdLoading(false)
        } else {
            message.error('Error getting departments')
        }
    }



    useEffect(() => {
        setDepartments()
    }, [])

    const onFinish = async (fieldsValue) => {
        const runtime_start = Date.now();
        console.log(fieldsValue)
        setIsGeneratingPlanning(true);
        setUploadingState('uploading')
        let priorityFileName = uploadFileNames.priority
        let forecastFileName = !disableCustomForecast ? uploadFileNames.forecast : ''
        let start_date = fieldsValue.planning_dates[0].format('YYYY-MM-DD')
        let end_date = fieldsValue.planning_dates[1].format('YYYY-MM-DD')
        console.log(priorityFileName, forecastFileName, start_date, end_date)

        if (forecastFileName === '') {
            let response = await GenerateForecast(start_date, end_date, fieldsValue.last_actuals)
            forecastFileName = response.data
            console.log('forecastfilename', forecastFileName)
        }

        setGeneratingPlanningStep(1)
        await delay(5000);

        setGeneratingPlanningStep(2)
        let response = await GeneratePlanning(forecastFileName, start_date, end_date, fieldsValue.department_id, priorityFileName)
        if (response.status === 200) {
            console.log(JSON.stringify(response.data));
            setUploadingState('success')
        } else {
            console.log(response);
            setUploadingState('error')
            return
        }
        setGeneratingPlanningStep(4)
        const runtime_end = Date.now();
        setTotalRuntime(parseInt((runtime_end - runtime_start) / 1000))
    };

    const onCustomForecastFileDisable = (checked) => {
        setDisableCustomForecast(!checked)
    }

    const UploadForecastFileToS3 = async ({ onSuccess, onError, file, onProgress }) => {
        setDisableSubmission(true);
        let fileName = ''
        let response = await GetPresignedUrl('forecast')
        if (response.status === 200) {
            fileName = response.data.fields.key
            console.log(JSON.stringify(response.data));
        } else {
            onError("Error generating url for s3");
            setDisableSubmission(false);
            return
        }

        response = await Upload_File_To_S3(response.data.url, response.data.fields.key, file)
        if (response.status === 200) {
            onSuccess("ok");
            console.log(JSON.stringify(response.data));

            let _ = uploadFileNames
            _.forecast = fileName
            setUploadFileNames(_)

            setDisableSubmission(false)
        } else {
            onError("Error uploading file to s3");
            setDisableSubmission(false);
            return
        }

    }

    const UploadPriorityFiletoS3 = async ({ onSuccess, onError, file, onProgress }) => {
        setDisableSubmission(true);
        let fileName = ''
        let response = await GetPresignedUrl('priority')
        if (response.status === 200) {
            fileName = response.data.fields.key
            console.log(JSON.stringify(response.data));
        } else {
            onError("Error generating url for s3");
            setDisableSubmission(false);
            return
        }

        response = await Upload_File_To_S3(response.data.url, response.data.fields.key, file)
        if (response.status === 200) {
            onSuccess("ok");
            console.log(JSON.stringify(response.data));

            let _ = uploadFileNames
            _.priority = fileName
            setUploadFileNames(_)

            setDisableSubmission(false);
        } else {
            onError("Error uploading file to s3");
            setDisableSubmission(false);
        }
    }


    return (
        <>
            {!isGeneratingPlanning && (
                <Row style={{ padding: '200px' }} justify='center' align='middle'>
                    <Col span={24}>
                        <Form name="time_related_controls" {...formItemLayout} onFinish={onFinish}>
                            <Form.Item name="department_id" label="Department Id" {...inputConfig}>
                                <Select defaultValue="Select a department Id" style={{ width: '100%' }} loading={departmentIdLoading}>
                                    {
                                        departmentIds.map((departmentId) => (
                                            <Option value={departmentId.departmentId} key={departmentId.departmentId}>{departmentId.name}</Option>
                                        ))
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item name="planning_dates" label="Select dates" {...rangeConfig}>
                                <RangePicker />
                            </Form.Item>
                            <Form.Item
                                name="priority_file"
                                label="Upload Priority file"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                action={onFinish}
                            >
                                <Upload name="logo" customRequest={UploadPriorityFiletoS3} listType="picture" maxCount={1}>
                                    <Button icon={<UploadOutlined style={{ color: '#1890ff' }} />}>Click to upload</Button>
                                </Upload>
                            </Form.Item>
                            <br />
                            <br />
                            <Form.Item
                                name="forecast_file"
                                label='Upload forecast file'
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                action={onFinish}
                                style={{ width: '500px' }}
                            >
                                <Switch style={{ margin: 5 }} onChange={onCustomForecastFileDisable} />
                                Upload Forecast File:
                                <Upload name="logo" customRequest={UploadForecastFileToS3} listType="picture" maxCount={1} style={{ margin: 5 }} >
                                    <Button disabled={disableCustomForecast} icon={<UploadOutlined style={{ color: '#1890ff' }} />}>Click to upload</Button>
                                </Upload>
                            </Form.Item>
                            {disableCustomForecast && (
                                <Form.Item name="last_actuals" label="Last Actuals" {...last_actuals_config}>
                                <Input placeholder="Last actuals to generate forecast" type='number' style={{ width: '100%', textAlign: 'start' }} />

                            </Form.Item>
                            )}
                            <Form.Item
                                wrapperCol={{
                                    xs: {
                                        span: 24,
                                        offset: 0,
                                    },
                                    sm: {
                                        span: 24,
                                        offset: 0,
                                    },
                                }}
                                style={{ textAlign: 'center' }}
                            >
                                <Button type="primary" htmlType="submit" disabled={disableSubmission}>
                                    Submit
                                </Button>
                            </Form.Item>
                            
                            
                        </Form>
                    </Col>
                </Row>
            )}

            {isGeneratingPlanning && (
                <Row justify='center' align='middle' style={{ padding: '200px' }} >
                    <Col span={24} style={{ textAlign: 'center', paddingBottom: 20 }}>
                        {
                            uploadingState === 'error' ?
                                (<CloseOutlined style={{ fontSize: '50px', color: 'red' }} />) :
                                uploadingState === 'success' ?
                                    (<CheckOutlined style={{ fontSize: '50px', color: 'green' }} />) :
                                    (<LoadingOutlined style={{ fontSize: '50px' }} />)
                        }





                    </Col>
                    <Col span={24} style={{ textAlign: 'center', paddingBottom: 200 }}>
                        {
                            uploadingState === 'error' ?
                                (<Typography.Text style={{ fontSize: '30px' }}>Failed to upload shifts to Tamigo.</Typography.Text>) :
                                uploadingState === 'success' ?
                                    (
                                    <>
                                        <Row>
                                            <Col span={24}>
                                                <Typography.Text style={{ fontSize: '30px' }}>Planning successfully imported!</Typography.Text>
                                            </Col>
                                            <Col span={24} style={{paddingBottom: 20}}>
                                            <Typography.Text style={{ fontSize: '10px' }}>Total Runtime: {totalRuntime} seconds </Typography.Text>
                                            </Col>
                                            <Col span={24}>
                                            <Space>
                                            <Button type="primary">Download Report (PDF)</Button>
                                            <Button onClick={() => window.location.reload()}>Create new planning</Button>
                                            </Space>
                                            
                                                </Col>
                                        </Row>
                                    </>
                                    ) :
                                    (<Typography.Text style={{ fontSize: '30px' }}>Planning is being generated.</Typography.Text>)
                        }



                    </Col>
                    <Col span={24} >
                        <Steps progressDot current={generatingPlanningStep} >
                            <Step title={generatingPlanningStep === 0 ? 'In Progress' : generatingPlanningStep >= 1 ? 'Finished' : 'Waiting'} description="Generating Forecast." />
                            <Step title={generatingPlanningStep === 1 ? 'In Progress' : generatingPlanningStep >= 2 ? 'Finished' : 'Waiting'} description="Uploading Forecast." />
                            <Step title={generatingPlanningStep === 2 ? 'In Progress' : generatingPlanningStep >= 3 ? 'Finished' : 'Waiting'} description="Generating Planning." />
                            <Step title={generatingPlanningStep === 3 ? 'In Progress' : generatingPlanningStep >= 4 ? 'Finished' : 'Waiting'} description="Importing Planning." />
                        </Steps>
                    </Col>
                </Row>
            )}


        </>
    )
}
