import { GENERATE_FORECAST } from './endpoints';
var axios = require('axios');

export default async function GenerateForecast(start_date, end_date, last_actuals) {

    var config = {
        method: 'get',
        url: GENERATE_FORECAST,
        headers: {
            'start_date': start_date,
            'end_date': end_date,
            'last_actuals': last_actuals
        }
    };
    try {
        // fetch data from a url endpoint
        const data = await axios(config);
        return data;

    } catch (error) {
        console.log("error", error);
        return error;
        // appropriately handle the error
    }

}
