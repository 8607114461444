import React, { useContext, useState } from 'react';

// create context for consuming and changing values
const LoadingMessageContext = React.createContext();
const LoadingMessageUpdateContext = React.createContext();

// used to access context value **
export function useLoadingMessageContext() {
    return useContext(LoadingMessageContext);
}

// used to update context value **
export function useLoadingMessageUpdateContext() {
    return useContext(LoadingMessageUpdateContext);
}

// wrap components that needs to access this context
export function LoadingMessageProvider({ children }) {
    const [message, setMessage] = useState("Creating Planning");

    function changeLoadingMessage(message) {
        setMessage(message);
    }

    return (
        <LoadingMessageContext.Provider value={message}>
            <LoadingMessageUpdateContext.Provider value={changeLoadingMessage}>
                {children}
            </LoadingMessageUpdateContext.Provider>
        </LoadingMessageContext.Provider>
    )
}
