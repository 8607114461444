import React, { useContext, useState } from 'react';

// create context for consuming and changing values
const LoadingContext = React.createContext();
const LoadingUpdateContext = React.createContext();

// used to access context value **
export function useLoadingContext() {
    return useContext(LoadingContext);
}

// used to update context value **
export function useLoadingUpdateContext() {
    return useContext(LoadingUpdateContext);
}

// wrap components that needs to access this context (index.js)
export function LoadingProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false);

    function toggleLoading() {
        setIsLoading(prevLoading => !prevLoading);
        console.log(isLoading)
    }

    return (
        <LoadingContext.Provider value={isLoading}>
            <LoadingUpdateContext.Provider value={toggleLoading}>
                {children}
            </LoadingUpdateContext.Provider>
        </LoadingContext.Provider>
    )
}
