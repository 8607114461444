import { useState } from "react";
import { Form, Button, Input, message } from 'antd';
import { formItemLayout, EmailInputConfig, PasswordInputConfig } from './config';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Spring, animated } from 'react-spring'
import './styles.css';


import { Auth } from "aws-amplify";

function ForgotPasswd({ callback }) {
    const [forgotPasswdLoader, setForgotPasswdLoader] = useState(false);
    const [forgotPasswdState, setForgotPasswdState] = useState('email');
    const [email, setEmail] = useState();

    const [confirmPasswordLoader, setConfirmPasswordLoader] = useState(false);

    const emailOnFinish = async function (fieldsValue) {
        setForgotPasswdLoader(true)
        Auth.forgotPassword(fieldsValue.email)
            .then(data => {
                console.log(data)
                setForgotPasswdState('code')
                setForgotPasswdLoader(false)
                setEmail(fieldsValue.email)
            })
            .catch(err => {
                console.log(err)
                message.error('Email Address not found');
                setForgotPasswdLoader(false)
            });
    }

    const codeOnFinish = async function (fieldsValue) {
        setConfirmPasswordLoader(true)
        if (fieldsValue.newPassword !== fieldsValue.confirmPassword) {
            message.error('Passwords don\'t match');
            setConfirmPasswordLoader(false)
            return
        }
        Auth.forgotPasswordSubmit(email, fieldsValue.code, fieldsValue.confirmPassword)
            .then(data => {
                console.log(data)
                setConfirmPasswordLoader(false)
                message.success('Password successfully changed!, please login.');
                callback('login')
            })
            .catch(err => {
                console.log(err)
                message.error('Invalid code provided, please try again');
                setConfirmPasswordLoader(false)

            });
    }

    return (
        <>
            {forgotPasswdState === 'email' ?
                <Spring to={{ opacity: 1 }} from={{ opacity: 0 }}>
                    {styles => (
                        <animated.div style={styles}>
                            <Form name="SignIn" {...formItemLayout} onFinish={emailOnFinish}>
                                <Form.Item name="email" {...EmailInputConfig}>
                                    <Input className="field" disabled={forgotPasswdLoader} placeholder="Email" prefix={<UserOutlined style={{ color: '#1890ff' }} />} />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="field" loading={forgotPasswdLoader}>
                                        Send Confirmation Code
                                    </Button>
                                </Form.Item>
                            </Form>
                        </animated.div>
                    )}
                </Spring>

                : forgotPasswdState === 'code' &&
                <Spring to={{ opacity: 1 }} from={{ opacity: 0 }}>
                    {styles => (
                        <animated.div style={styles}>
                            <Form name="NewPassword" {...formItemLayout} onFinish={codeOnFinish}>
                                <Form.Item name="code" >
                                    <Input className="field" type='text' disabled={confirmPasswordLoader} placeholder="Enter code sent to your email" prefix={<UserOutlined style={{ color: '#1890ff' }} />} />
                                </Form.Item>
                                <Form.Item name="newPassword" {...PasswordInputConfig}>
                                    <Input className="field" type='password' disabled={confirmPasswordLoader} placeholder="New Password" prefix={<UserOutlined style={{ color: '#1890ff' }} />} />
                                </Form.Item>
                                <Form.Item name="confirmPassword"  {...PasswordInputConfig}>
                                    <Input className="field" type='password' disabled={confirmPasswordLoader} placeholder="Confirm New Password" prefix={<LockOutlined style={{ color: '#1890ff' }} />} />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="field" loading={confirmPasswordLoader}>
                                        Change Password
                                    </Button>
                                </Form.Item>
                            </Form>
                        </animated.div>
                    )}
                </Spring>
            }

        </>
    )
}

export default ForgotPasswd;