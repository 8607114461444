import { useState } from "react";
import { Form, Button, Input, message, Typography, Row } from 'antd';
import { formItemLayout, EmailInputConfig, PasswordInputConfig } from './config';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Spring, animated } from 'react-spring'
import ForgotPasswd from './forgot_passwd';
import './styles.css';


import { Auth } from "aws-amplify";

const { Text } = Typography;

function Login() {
    const [loginLoader, setLoginLoader] = useState(false);
    const [loginState, setLoginState] = useState('login');
    const [user, setUser] = useState();

    const [confirmPasswordLoader, setConfirmPasswordLoader] = useState(false);

    const SignInOnFinish = async function (fieldsValue) {
        setLoginLoader(true)
        try {
            const user = await Auth.signIn(fieldsValue.email, fieldsValue.password)
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                message.error('You need to set a new password.')
                setUser(user)
                setLoginState('newPassword')
            } else {
                message.success('Successfully Logged In');
            }
            console.log(user)

        } catch (error) {
            message.error('Invalid Email or Password');
        }
        setLoginLoader(false)
    }

    const ConfirmPasswordOnFinish = async function (fieldsValue) {
        setConfirmPasswordLoader(true)
        if (fieldsValue.newPassword !== fieldsValue.confirmPassword) {
            message.error('Passwords don\'t match');
            setConfirmPasswordLoader(false)
            return
        }

        Auth.completeNewPassword(
            user,               // the Cognito User Object
            fieldsValue.newPassword,       // the new password
        ).then(user => {
            // at this time the user is logged in if no MFA required
            message.success('Successfully Logged In');
            console.log(user);
        }).catch(e => {
            console.log(e);
        });
    }

    const callback = (value) => {
        console.log(value)
        setLoginState(value)
    }


    return (
        <>
            <Row style={{ padding: '200px' }} justify='center' align='middle'>
                {loginState === 'login' ?
                    <Spring to={{ opacity: 1 }} from={{ opacity: 0 }}>
                        {styles => (
                            <animated.div style={styles}>
                                <Form name="SignIn" {...formItemLayout} onFinish={SignInOnFinish}>
                                    <Form.Item name="email" {...EmailInputConfig}>
                                        <Input className="field" disabled={loginLoader} placeholder="Email" prefix={<UserOutlined style={{ color: '#1890ff' }} />} />
                                    </Form.Item>
                                    <Form.Item name="password"  {...PasswordInputConfig}>
                                        <Input className="field" type='password' disabled={loginLoader} placeholder="Password" prefix={<LockOutlined style={{ color: '#1890ff' }} />} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Text type="secondary" onClick={() => setLoginState('forgotPassword')} style={{ cursor: 'pointer', color: '#1890ff' }}>Forgot Your Password?</Text>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="field" loading={loginLoader}>
                                            Login
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </animated.div>
                        )}
                    </Spring>

                    : loginState === 'newPassword' ?
                    <Spring to={{ opacity: 1 }} from={{ opacity: 0 }}>
                        {styles => (
                            <animated.div style={styles}>
                                <Form name="NewPassword" {...formItemLayout} onFinish={ConfirmPasswordOnFinish}>
                            <Form.Item name="newPassword" {...PasswordInputConfig}>
                                <Input className="field" type='password' disabled={confirmPasswordLoader} placeholder="New Password" prefix={<UserOutlined style={{ color: '#1890ff' }} />} />
                            </Form.Item>
                            <Form.Item name="confirmPassword"  {...PasswordInputConfig}>
                                <Input className="field" type='password' disabled={confirmPasswordLoader} placeholder="Confirm New Password" prefix={<LockOutlined style={{ color: '#1890ff' }} />} />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="field" loading={loginLoader}>
                                    Change Password
                                </Button>
                            </Form.Item>
                        </Form>
                            </animated.div>
                        )}
                    </Spring>
                         :
                        <ForgotPasswd callback={callback} />
                }
            </Row>

        </>
    )
}

export default Login;