var axios = require('axios');

export default async function Upload_File_To_S3(URL, key, file) {
    var config = {
        method: 'put',
        url: URL + key,
        headers: {
            'x-amz-acl': 'public-read'
        },
        data: file
    };
    
    try {
        // fetch data from a url endpoint
        const data = await axios(config);
        return data;

    } catch (error) {
        console.log("error", error);
        return error;
        // appropriately handle the error
    }
}