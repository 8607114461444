export const normFile = (e) => {
    console.log('Upload event:', e);
  
    if (Array.isArray(e)) {
      return e;
    }
  
    return e && e.fileList;
  };

export const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};

export const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Select start and end date',
        },
    ],
};

export const inputConfig = {
    rules: [
        {
            required: true,
            message: 'Please enter Department Id',
        },
    ],
};

export const last_actuals_config = {
    rules: [
        {
            required: true,
            message: 'Please enter last actuals',
        },
    ],
};