import { GENERATE_PLANNING } from './endpoints';
var axios = require('axios');

export default async function GeneratePlanning(forecast_file_name, start_date, end_date, department_id, priority_file_name) {
    if (!priority_file_name) {
        var config = {
            method: 'post',
            url: GENERATE_PLANNING,
            headers: {
                'dry_run': 'false',
                'forecast_filename': forecast_file_name,
                'start_time': start_date,
                'end_time': end_date,
                'dept_id': department_id
            }
        };
    } else {
        var config = {
            method: 'post',
            url: GENERATE_PLANNING,
            headers: {
                'dry_run': 'false',
                'forecast_filename': forecast_file_name,
                'start_time': start_date,
                'end_time': end_date,
                'dept_id': department_id,
                'priority_filename': priority_file_name
            }
        };
    }
    
    
    try {
        // fetch data from a url endpoint
        const data = await axios(config);
        return data;

    } catch (error) {
        console.log("error", error);
        return error;
        // appropriately handle the error
    }
}